module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"languages":["en","lt","lv","ee"],"defaultLanguage":"lt","siteUrl":"https://zuklida.lt","i18nextOptions":{"fallbackLng":"lt","interpolation":{"escapeValue":false}},"localeJsonSourceName":"locales","load":"currentOnly"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Žuklida - Žūklės prekės, žūklės parduotuvė","short_name":"Žuklida","start_url":"/","background_color":"#ffffff","display":"minimal-ui","icon":"src/images/zuklida-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"18ddd9200bfd062284aecfe58506bc19"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
