import React from "react"
import CartProvider from "./src/context/cartcontext"
import { AuthProvider } from "./src/context/auth"
import "./i18n"

export const wrapRootElement = ({ element }) => {
  return (
    <AuthProvider>
      <CartProvider>{element}</CartProvider>
    </AuthProvider>
  )
}
